import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { IActionMapping, KEYS, TreeComponent } from 'angular-tree-component';
import { ITreeNode, TreeNode } from 'angular-tree-component/dist/defs/api';
import { Subscription } from 'rxjs';
import { debounceTime, map, startWith, tap } from 'rxjs/operators';
import { BusService } from '../../core/molecular/services/bus.service';
import { EventsService } from '../../core/molecular/services/events.service';
import { EditorStateService } from '../../core/services/editor-state.service';
import { GenericDialogService } from '../../core/services/generic-dialog.service';
import { LocalStorageService } from '../../core/services/local-storage.service';
import { ToolsService } from '../../core/services/tools.service';
import { Constants } from '../../shared/constants';
import { DragType } from '../../shared/enums/drag-type.enum';
import { LeapXLEventType } from '../../shared/enums/leapxl-event-type.enum';
import { RepresentativeMoleculesType } from '../../shared/enums/representative-molecules-types.enum';
import { LeapXLEvent } from '../../shared/representative-molecule/interfaces/leapxl-event';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';
import { DragService } from '../../shared/representative-molecule/services/drag.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { DraggableWindowService, DraggableWindowType } from '../../shared/services/draggable-window.service';
import { FactoryParticleService } from '../../shared/services/factory-particle.service';
import { SnackerService } from '../../shared/services/snacker.service';
import { WorkAreaService } from '../workarea.service';

@Component({
  selector: 'app-events-panel',
  templateUrl: './events-panel.component.html',
  styleUrls: ['./events-panel.component.scss'],
})
export class EventsPanelComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('editEventNameInput', { static: false })
  editEventNameInput: ElementRef;
  @ViewChild('newEventNameInput', { static: false })
  newEventNameInput: ElementRef;
  @ViewChild('editViewNameInput', { static: false })
  treeComponent: TreeComponent;
  @ViewChild('EventsTree', { static: false })
  eventsTreeComponent: TreeComponent;
  
  inDebounce = null;
  subscriptions: Subscription;
  treeExpanded = false;
  filterValue = '';
  newEvent: LeapXLEvent = null;
  createNewEvent = false;
  actionMapping: IActionMapping = {
    mouse: {
      click: (tree, node, $event) => {
        console.log(tree, node, $event);
        const repMolecule = this.busService.Get(node.data.id);
        this.RefreshUI();
        node.expand();
        this.RefreshUI();
        this.communicationService.Event.System.Update.$ChangesOnMolecules.emit(null);
        this.workAreaService.ShowElementFocusedMenu(repMolecule);
        const leapEvent = new LeapXLEvent({
          sourceId: repMolecule.Id,
          eventType: node.data.eventType,
          eventSource: 'Molecule',
          eventName: node.data.name,
        });
        this.ShowEventUses($event, leapEvent);
        this.RefreshUI();
        
        // return;
        setTimeout(() => {
          const item = document.querySelector(`#gridsterItem-${ node.data.id }`);
          
          if (item) {
            this.communicationService.Event.Editor.$WorkAreaDetection.emit(false);
            item.classList.add('pulse');
            this.RefreshUI();
            setTimeout(() => {
              item.classList.remove('pulse');
              this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
            }, 1000);
          }
        }, 150);
      },
      dragStart: (tree, node, $event) => {
        const highlightClass = 'highlight-rep-molecule';
        const elements = document.querySelectorAll(`.${ highlightClass }`);
        if (elements) {
          elements.forEach(el => {
            el.classList.remove(highlightClass);
          });
        }
        
        this.communicationService.Event.Editor.$WorkAreaDetection.emit(false);
        console.log('dragstart node', node);
        this.SetDragStart(node.data.completeName, node.data);
        this.RefreshUI();
      },
      dragEnd: (tree, node, $event) => {
        this.communicationService.Event.Editor.$WorkAreaDetectionDisableFor.emit(200);
        this.SetDragEnd();
        this.RefreshUI();
      },
      mouseOver: (tree, node, $event) => {
        if (node && !node.data.isView) {
          const highlightClass = 'highlight-rep-molecule';
          const element = document.querySelector(`#gridsterItem-${ node.data.id }`);
          if (element) {
            element.classList.add(highlightClass);
          }
        }
      },
      mouseOut: (tree, node, $event) => {
        const highlightClass = 'highlight-rep-molecule';
        const elements = document.querySelectorAll(`.${ highlightClass }`);
        if (elements) {
          elements.forEach(el => {
            el.classList.remove(highlightClass);
          });
        }
      },
    },
    keys: {
      [KEYS.RIGHT]: null,
      [KEYS.LEFT]: null,
      [KEYS.DOWN]: null,
      [KEYS.UP]: null,
      [KEYS.SPACE]: null,
      [KEYS.ENTER]: null,
    },
  };
  options: any;
  busServiceElements = 0;
  renameEvent = '';
  newEventName = '';
  editEvent: LeapXLEvent = null;
  preventEventSourceClick = false;
  timerEventSourceClick = null;
  nodes: TreeNode[] = [];
  groupedCustomEvents = [];
  windowHeight = 0;
  filteringCustomEvents = false;
  customEventsSearch = new FormControl('');
  eventsSearch = new FormControl('');
  filteredLeapXLCommunicationEvents: string[] = [];
  filteredSystemEvents: { name: string; icon: string }[] = [];
  filteredViewEvents: string[] = [];
  lastUsedEvents = {
    leapxlcommunication: '',
    system: '',
    view: '',
    molecule: '',
    custom: '',
  };
  
  constructor(
    private busService: BusService,
    public workAreaService: WorkAreaService,
    public cobbleService: CobbleService,
    private snackerService: SnackerService,
    public eventsService: EventsService,
    public communicationService: CommunicationService,
    private dragService: DragService,
    private toolsService: ToolsService,
    private editorStateService: EditorStateService,
    private draggableWindowService: DraggableWindowService,
    private factoryParticleService: FactoryParticleService,
    private localStorageService: LocalStorageService,
    private changeDetectorRef: ChangeDetectorRef,
    private genericDialogService: GenericDialogService,
    private renderer: Renderer2,
  ) {
    // changeDetectorRef.detach();
    this.options = {
      actionMapping: this.actionMapping,
      useVirtualScroll: false,
      nodeHeight: 22,
      allowDrag: node => {
        return node.data.isEvent;
      },
      allowDrop: (nodeDragged, { parent, index }) => false,
    };
  }
  
  get actualEditorViewsIds(): number[] {
    return this.workAreaService.actualEditorViews.length > 0 ? this.workAreaService.actualEditorViews.map(
      v => v.id) : [];
  }
  
  RefreshUI() {
    this.changeDetectorRef.detectChanges();
  }
  
  RefreshUIDelayed() {
    this.changeDetectorRef.detectChanges();
    setTimeout(() => {
      this.changeDetectorRef.detectChanges();
    }, 200);
  }
  
  ngAfterViewInit(): void {
    let interval = setInterval(() => {
      if (this.cobbleService.Cobble.id > 0) {
        this.GetCustomEventForApp();
        clearInterval(interval);
        interval = null;
      }
      // console.log('looping');
    }, 200);
    
    this.setLastUsedElements();
    this.setTreeViewportHeight();
  }
  
  GetCustomEventForApp() {
    this.eventsService.GetEventsForApp()
    .subscribe(events => {
      console.log('events', events);
      this.eventsService.CustomEvents = events.sort(this.toolsService.CompareValues('EventName'));
      this.RefreshUI();
      
      this.GroupCustomEvents();
    });
  }
  
  ngOnInit() {
    this.windowHeight = window.innerHeight;
    this.subscriptions = this.communicationService.Event.System.App.$AppLoaded.subscribe(loaded => {
      console.log('=event='); // console.log('app loaded');
      const devtoolsOpen = this.localStorageService.Get('devtools');
      
      if (devtoolsOpen) {
        this.snackerService.ShowMessageOnBottom(
          'Opening the BROWSER CONSOLE can affect LeapXL performance and be a SECURITY RISK');
        localStorage.removeItem(`${ Constants.LocalStoragePrefix }devtools`);
      }
      this.RefreshEventsTree();
      this.RefreshUI();
    });
    
    this.subscriptions.add(
      this.communicationService.Event.Editor.EventsTree.$RefreshCustomEvents.subscribe(() => {
        console.log('=event=');
        this.GetCustomEventForApp();
      }),
    );
    
    this.subscriptions.add(
      this.communicationService.Event.Editor.Views.$VisibilityChange.subscribe(view => {
        console.log('=event='); // console.log('$VisibilityChange');
        setTimeout(() => {
          this.RefreshEventsTree();
          this.RefreshUI();
        }, 200);
      }),
    );
    
    this.subscriptions.add(
      this.communicationService.Event.Editor.Views.$SwitchView.subscribe(() => {
        console.log('=event=');
        setTimeout(() => {
          this.RefreshEventsTree();
          this.RefreshUI();
        }, 200);
      }),
    );
    
    this.subscriptions.add(
      this.communicationService.Event.Editor.$MoleculeRemoved.pipe(debounceTime(200))
      .subscribe(moleculeId => {
        console.log('=event=');
        console.log('$MoleculeRemoved');
        this.RefreshEventsTree();
        this.RefreshUI();
      }),
    );
    
    this.subscriptions.add(
      this.communicationService.Event.Editor.EventsTree.$Highlight.pipe(debounceTime(0))
      .subscribe((path: number[]) => {
        console.log('=event=');
        this.HighlightPath(path);
        this.RefreshUI();
      }),
    );
    
    this.subscriptions.add(
      this.communicationService.Event.Editor.EventsTree.$RemoveHighlight.subscribe((path: number[]) => {
        console.log('=event=');
        this.RemoveEventsHighlight(path);
        this.RefreshUI();
      }),
    );
    
    this.subscriptions.add(
      this.communicationService.Event.Editor.EventsTree.$Collapse.subscribe((path: number[]) => {
        console.log('=event=');
        this.CollapseAll();
        this.RefreshUI();
      }),
    );
    
    this.subscriptions.add(
      this.communicationService.Event.Editor.EventsTree.$RefreshEventsTree.pipe(debounceTime(200))
      .subscribe(value => {
        // console.log('$RefreshEventsTree');
        
        console.log('=event=');
        this.RefreshEventsTree();
        this.RefreshUI();
      }),
    );
    
    this.subscriptions.add(
      this.communicationService.Event.Editor.EventsTree.$OpenEventsSection.subscribe(section => {
        // console.log('$RefreshEventsTree');
        
        console.log('=event=');
        if (!this.workAreaService.eventsPanelSectionsState[section]) {
          this.ToggleSection(section);
        }
      }),
    );
    
    this.subscriptions.add(
      this.communicationService.Event.Editor.$MoleculeAdded.subscribe(moleculeId => {
        console.log('=event='); // console.log('molecule added');
        this.RefreshEventsTree();
        this.RefreshUI();
      }),
    );
    
    this.subscriptions.add(
      this.customEventsSearch.valueChanges
      .pipe(
        tap(() => {
          this.filteringCustomEvents = true;
          this.RefreshUI();
        }),
        startWith(''),
        debounceTime(300),
        map(value => this.filterCustomEvents(value || '')),
      )
      .subscribe(filteredEvents => {
        this.GroupCustomEvents(filteredEvents);
        this.filteringCustomEvents = false;
        this.RefreshEventsTree();
        this.RefreshUI();
      }),
    );
    
    this.subscriptions.add(
      this.eventsSearch.valueChanges
      .pipe(
        startWith(''),
        debounceTime(300),
        map(value => this.filterEvents(value || '')),
      )
      .subscribe(value => {
        this.customEventsSearch.setValue(this.eventsSearch.value);
        const sections = ['leapxlCommunication', 'system', 'view', 'elements', 'custom'];
        
        sections.forEach(section => {
          this.workAreaService.eventsPanelSectionsSearchState[section] = false;
          
          if (!value) {
            return;
          }
          
          switch (section) {
            case 'leapxlCommunication':
              if (this.filteredLeapXLCommunicationEvents.length > 0) {
                this.workAreaService.eventsPanelSectionsSearchState[section] = true;
              }
              break;
            case 'system':
              if (this.filteredSystemEvents.length > 0) {
                this.workAreaService.eventsPanelSectionsSearchState[section] = true;
              }
              break;
            case 'view':
              if (this.filteredViewEvents.length > 0) {
                this.workAreaService.eventsPanelSectionsSearchState[section] = true;
              }
              break;
            case 'custom':
              if (this.groupedCustomEvents.length > 0) {
                this.workAreaService.eventsPanelSectionsSearchState[section] = true;
              }
              break;
          }
        });
        
        const eventBody = document.querySelector('.events-panel .event-section-body');
        const eventsPanel = document.querySelector('.events-panel');
        
        if (eventBody) {
          this.renderer.setStyle(eventBody, 'max-height', 'fit-content');
          this.renderer.setStyle(eventBody, 'overflow', 'hidden');
        }
        
        if (eventsPanel) {
          this.renderer.setStyle(eventsPanel, 'max-height', `${ this.windowHeight - 156 }px`);
        }
        
        this.setTreeViewportHeight();
        this.RefreshEventsTree();
        this.RefreshUI();
      }),
    );
    
    this.subscriptions.add(
      this.communicationService.Event.Editor.Preferences.$PreferenceChange.subscribe(preference => {
        if (preference === 'compactToolBarPosition') {
          this.setTreeViewportHeight();
        }
      }),
    );
    
    this.subscriptions.add(
      this.communicationService.Event.Editor.$LastUsedElement.subscribe(lastUsedElements => {
        if (!lastUsedElements.event) {
          return;
        }
        
        for (const [key, value] of Object.entries(this.lastUsedEvents)) {
          if (lastUsedElements.event[key]) {
            this.lastUsedEvents[key] = lastUsedElements.event[key];
          }
        }
        this.RefreshUI();
      }),
    );
  }
  
  filterCustomEvents(value: string): LeapXLEvent[] {
    const filterValue = value.toLowerCase();
    return this.eventsService.CustomEvents.filter(event => event.EventName.toLowerCase()
    .includes(filterValue));
  }
  
  filterEvents(value: string): string {
    const filterValue = value.toLowerCase();
    this.filteredLeapXLCommunicationEvents = this.eventsService
    .GetLeapXLCommunicationEvents()
    .filter(event => event.toLowerCase()
    .includes(filterValue));
    this.filteredSystemEvents = this.eventsService.GetSystemEvents()
    .filter(event => event.name.toLowerCase()
    .includes(filterValue));
    this.filteredViewEvents = this.eventsService.GetViewEvents()
    .filter(event => event.toLowerCase()
    .includes(filterValue));
    this.GroupCustomEvents(this.filterCustomEvents(value));
    return value;
  }
  
  HighlightPath(path: number[]) {
    this.RefreshUI();
    
    if (this.eventsTreeComponent) {
    } else {
      return;
    }
    
    // remove cobble parent
    path.shift();
    
    const firstparent = path.shift();
    let node: ITreeNode = this.eventsTreeComponent.treeModel.getNodeBy(n => n.data.id === firstparent);
    let el = document.querySelector(`.event-path-${ firstparent }`);
    if (el) {
      el.classList.add('selected-element-node');
    }
    this.RefreshUI();
    
    this.eventsTreeComponent.treeModel.roots.forEach(rootNode => {
      if (rootNode.data.id === firstparent) {
        rootNode.expand();
      } else {
        // rootNode.collapse();
      }
      this.RefreshUI();
    });
    
    if (node) {
      path.forEach(p => {
        node.children.forEach(treeNode => {
          if (treeNode.data.id === p) {
            treeNode.expand();
          } else {
            treeNode.collapse();
          }
          this.RefreshUI();
        });
        
        node = this.eventsTreeComponent.treeModel.getNodeBy(n => n.data.id === p);
        
        if (node) {
          el = document.querySelector(`.event-path-${ p }`);
          if (el) {
            el.classList.add('selected-element-node');
            el.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
          this.RefreshUI();
        }
      });
    }
  }
  
  RemoveEventsHighlight(path?: number[]) {
    this.RefreshUI();
    // console.log('remove events highlight');
    let highlitedEvents = [];
    
    if (path) {
      path.forEach(p => {
        const identifier = `.event-path-${ p }`;
        const el = document.querySelector(identifier);
        if (el) {
          highlitedEvents.push(el);
          this.RefreshUI();
        }
      });
    } else {
      highlitedEvents = document.querySelectorAll(`.selected-element-node`) as unknown as any[];
    }
    
    highlitedEvents.forEach(hl => hl.classList.remove('selected-element-node'));
    this.RefreshUI();
  }
  
  CollapseAll(event?: MouseEvent) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.RefreshUI();
    if (this.eventsTreeComponent) {
      // console.log('collapsing events');
      this.eventsTreeComponent.treeModel.collapseAll();
      this.RefreshUI();
    }
  }
  
  RefreshEventsTree() {
    this.changeDetectorRef.reattach();
    this.nodes = [];
    
    // console.log('this.workAreaService.actualEditorViews', this.workAreaService.actualEditorViews);
    
    const currentView = this.workAreaService.ActualView;
    
    if (currentView) {
      
      const wgView = this.busService.GetViewWorkgroupsAndStepperMolecules(this.cobbleService.Cobble,
        currentView.id);
      
      if (wgView) {
        wgView.forEach(molecule => {
          const wgView = {
            id: molecule.Id,
            name: `${ molecule.Properties.name }`,
            isRoot: true,
            icon: molecule.Icon,
            children: [],
            draggable: false,
            isEvent: false,
          };
          
          const wg = this.busService.Get(molecule.Id.toString());
          let wgEvents = wg.Events;
          
          if (wg.Properties.customControl && wg.Properties.customControl.events) {
            wgEvents = [...wg.Events, ...wg.Properties.customControl.events.map(ce => ce.name)];
          }
          
          wgEvents.sort()
          .forEach(event => {
            wgView.children.push({
              id: `${ molecule.Id }`,
              name: `${ event.replace('-', ' ') }`,
              completeName: `${ molecule.Properties.name } - ${ event.toUpperCase() }`,
              children: [],
              isRoot: false,
              eventSource: 'Molecule',
              eventType: event,
              isEvent: true,
              icon: 'flash_on',
              draggable: false,
            });
          });
          
          this.busService.GetChildrenElementIds(molecule.Id)
          .forEach(wgChildId => {
            const wgChild = this.busService.Get(wgChildId.id);
            const childView = {
              id: wgChild.Id,
              name: `${ wgChild.Properties.name }`,
              children: [],
              isRoot: false,
              isEvent: false,
              icon: wgChild.Icon,
              draggable: false,
            };
            
            let wgChildEvents = wgChild.Events;
            
            if (wgChild.Properties.customControl && wgChild.Properties.customControl.events) {
              wgChildEvents = [...wgChild.Events,
                ...wgChild.Properties.customControl.events.map(ce => ce.name)];
            }
            
            wgChildEvents.sort()
            .forEach(event => {
              if (event !== 'unique') {
                childView.children.push({
                  id: `${ wgChild.Id }`,
                  name: `${ event.replace('-', ' ') }`,
                  completeName: `${ wgChild.Properties.name } - ${ event.toUpperCase() }`,
                  children: [],
                  isRoot: false,
                  eventType: event,
                  eventSource: 'Molecule',
                  isEvent: true,
                  icon: 'flash_on',
                  draggable: false,
                });
              }
            });
            
            if (
              wgChild.MoleculeSubType === RepresentativeMoleculesType.Table ||
              wgChild.MoleculeSubType === RepresentativeMoleculesType.WorkGroup ||
              wgChild.MoleculeSubType === RepresentativeMoleculesType.Stepper
            ) {
              const tableChildren = this.busService.DirectChildrenElements(wgChild.Id);
              
              tableChildren.forEach(tc => {
                const tcView = {
                  id: tc.Id,
                  name: `${ tc.Properties.name }`,
                  children: [],
                  isRoot: false,
                  isEvent: false,
                  icon: tc.Icon,
                  draggable: false,
                };
                
                let tcEvents = tc.Events;
                
                if (tc.Properties.customControl && tc.Properties.customControl.events) {
                  tcEvents = [...tc.Events, ...tc.Properties.customControl.events.map(ce => ce.name)];
                }
                
                tcEvents.sort()
                .forEach(tcEvent => {
                  tcView.children.push({
                    id: `${ tc.Id }`,
                    name: `${ tcEvent.replace('-', ' ') }`,
                    completeName: `${ tc.Properties.name } - ${ tcEvent.toUpperCase() }`,
                    children: [],
                    isRoot: false,
                    eventType: tcEvent,
                    eventSource: 'Molecule',
                    isEvent: true,
                    icon: 'flash_on',
                    draggable: false,
                  });
                });
                
                if (
                  tc.MoleculeSubType === RepresentativeMoleculesType.Table ||
                  tc.MoleculeSubType === RepresentativeMoleculesType.WorkGroup ||
                  tc.MoleculeSubType === RepresentativeMoleculesType.Stepper
                ) {
                  const lastChildren = this.busService.DirectChildrenElements(tc.Id);
                  
                  lastChildren.forEach(lc => {
                    const lcView = {
                      id: lc.Id,
                      name: `${ lc.Properties.name }`,
                      children: [],
                      isRoot: false,
                      isEvent: false,
                      icon: lc.Icon,
                      draggable: false,
                    };
                    
                    let lcEvents = lc.Events;
                    
                    if (lc.Properties.customControl && lc.Properties.customControl.events) {
                      lcEvents = [...lc.Events, ...lc.Properties.customControl.events.map(ce => ce.name)];
                    }
                    
                    lcEvents.sort()
                    .forEach(lcEvent => {
                      lcView.children.push({
                        id: `${ lc.Id }`,
                        name: `${ lcEvent.replace('-', ' ') }`,
                        completeName: `${ lc.Properties.name } - ${ lcEvent.toUpperCase() }`,
                        children: [],
                        isRoot: false,
                        eventType: lcEvent,
                        eventSource: 'Molecule',
                        isEvent: true,
                        icon: 'flash_on',
                        draggable: false,
                      });
                    });
                    
                    ///////////////////////////////////
                    if (
                      lc.MoleculeSubType === RepresentativeMoleculesType.Table ||
                      lc.MoleculeSubType === RepresentativeMoleculesType.WorkGroup ||
                      lc.MoleculeSubType === RepresentativeMoleculesType.Stepper
                    ) {
                      const sChildren = this.busService.DirectChildrenElements(lc.Id);
                      
                      sChildren.forEach(sc => {
                        const scView = {
                          id: sc.Id,
                          name: `${ sc.Properties.name }`,
                          children: [],
                          isRoot: false,
                          isEvent: false,
                          icon: sc.Icon,
                          draggable: false,
                        };
                        
                        let scEvents = sc.Events;
                        
                        if (sc.Properties.customControl && sc.Properties.customControl.events) {
                          scEvents = [...sc.Events, ...sc.Properties.customControl.events.map(ce => ce.name)];
                        }
                        
                        scEvents.sort()
                        .forEach(scEvent => {
                          scView.children.push({
                            id: `${ sc.Id }`,
                            name: `${ scEvent.replace('-', ' ') }`,
                            completeName: `${ sc.Properties.name } - ${ scEvent.toUpperCase() }`,
                            children: [],
                            isRoot: false,
                            eventType: scEvent,
                            eventSource: 'Molecule',
                            isEvent: true,
                            icon: 'flash_on',
                            draggable: false,
                          });
                        });
                        lcView.children.push(scView);
                      });
                    }
                    ///////////////////////////////////
                    
                    tcView.children.push(lcView);
                  });
                }
                
                childView.children.push(tcView);
              });
            }
            
            wgView.children.push(childView);
          });
          
          this.nodes.push(wgView);
          // console.log(this.nodes);
          setTimeout(() => {
            this.changeDetectorRef.detach();
          }, 1000);
        });
      }
      
      this.busServiceElements = Object.keys(this.busService.Channel).length - 1;
      
      setTimeout(() => {
        // console.log(this.workAreaService.elementsSelected.length);
        this.workAreaService.elementsSelected.forEach(es => es.HighlightEventsPath());
      }, 300);
      
      this.setTreeViewportHeight();
    }
  }
  
  onEvent(e: any) {
    this.RefreshUI();
    this.treeExpanded = e.isExpanded;
    this.RefreshUI();
  }
  
  EventDragStartHandler(e: any, node: any) {
    // this.RefreshUI();
    //
    if (node.eventType === 'View Broadcast') {
      node.eventSource = `${ node.eventSource }-${ this.workAreaService.ActualView.id }`;
    }
    
    console.log('EventDragStartHandler', e, node);
    this.SetDragStart(e, node);
    // this.RefreshUI();
  }
  
  SetDragStart(eventText: string, node: any) {
    console.log(node);
    // this.RefreshUI();
    this.dragService.dragData = {
      dragType: DragType.Event,
      data: new LeapXLEvent({
        particleId: this.toolsService.GenerateGuid(),
        id: this.toolsService.GenerateGuid(),
        sourceId: node.eventSource === 'LeapXLCommunication' ? '' : node.id,
        eventType: node.eventType,
        eventSource: node.eventSource,
        eventName: node.name,
      }),
    };
    this.dragService.dragginEvent = true;
    this.dragService.dragText = `Attach ${ eventText } event`;
    // this.RefreshUI();
  }
  
  NewEvent(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
    
    this.RefreshUI();
    setTimeout(() => {
      this.createNewEvent = true;
      this.newEvent = this.factoryParticleService.GenerateEvent(LeapXLEventType.Custom);
      this.RefreshUI();
      
      setTimeout(() => {
        this.RefreshUI();
        this.newEventNameInput.nativeElement.focus();
        this.newEventNameInput.nativeElement.select();
        this.RefreshUI();
      }, 100);
    }, 50);
  }
  
  AddEvent() {
    this.RefreshUI();
    
    console.log('debug');
    
    if (!!this.eventsService.CustomEvents.find(e => e.EventName === this.newEventName)) {
      this.snackerService.ShowMessageOnBottom('An event with this name already exists', 'repeat_one_on');
      
      return;
    }
    
    this.newEvent.EventName = this.newEventName.trim();
    this.newEvent.SetIdentifier();
    this.eventsService.CustomEvents.push(this.newEvent);
    
    let newEventElement = null;
    
    this.eventsService.SaveEvent([this.newEvent])
    .subscribe(result => {
      try {
        newEventElement = document.querySelector(`.event-custom-${ this.newEvent.Identifier }`);
        
        if (newEventElement) {
          newEventElement.classList.add('saving-custom-event');
        }
        
        this.StopEventCreation();
        setTimeout(() => {
          this.snackerService.ShowMessageOnBottom('Custom event added', 'add_circle');
          if (newEventElement) {
            newEventElement.classList.remove('saving-custom-event');
          }
        }, 400);
      } catch (e) {
        this.StopEventCreation();
        console.error('Error saving custom event');
      }
    });
    
    this.GroupCustomEvents(
      this.customEventsSearch.value ? this.filterCustomEvents(this.customEventsSearch.value) : null);
    this.RefreshUI();
  }
  
  StopEventCreation() {
    this.createNewEvent = false;
    this.newEvent = null;
    this.newEventName = '';
    this.RefreshUI();
    this.GroupCustomEvents(
      this.customEventsSearch.value ? this.filterCustomEvents(this.customEventsSearch.value) : null);
  }
  
  RenameEvent(event: LeapXLEvent) {
    this.RefreshUI();
    clearTimeout(this.timerEventSourceClick);
    this.preventEventSourceClick = true;
    this.workAreaService.arrowKeysMovementDisabled = true;
    this.renameEvent = event.EventName;
    this.editEvent = event;
    setTimeout(() => {
      this.RefreshUI();
      this.editEventNameInput.nativeElement.focus();
      this.editEventNameInput.nativeElement.select();
      this.RefreshUI();
    }, 100);
  }
  
  SaveEventName(event: LeapXLEvent) {
    this.RefreshUI();
    
    if (!!this.eventsService.CustomEvents.find(e => e.EventName === this.renameEvent)) {
      this.snackerService.ShowMessageOnBottom('An event with this name already exists', 'repeat_one_on');
      return;
    }
    
    const repMoleculesUsingEvent = this.busService.GetRepresentativeMoleculesWithEvent(event.EventName);
    this.eventsService.RenameEvent(event.EventName, this.renameEvent)
    .subscribe(response => {
      repMoleculesUsingEvent.forEach(repMolecule => {
        const repMolEvent = repMolecule.GetEventByEventName(event.EventName);
        repMolEvent.EventName = this.renameEvent;
        repMolEvent.SetIdentifier();
        repMolecule.SaveProperty('buses', 'Event renamed')
        .subscribe();
      });
      
      event.EventName = this.renameEvent;
      event.SetIdentifier();
      this.renameEvent = '';
      this.StopEventRename();
      this.RefreshUI();
    });
  }
  
  StopEventRename() {
    this.RefreshUI();
    this.renameEvent = '';
    this.editEvent = null;
    this.RefreshUI();
    setTimeout(() => {
      this.workAreaService.arrowKeysMovementDisabled = false;
      this.RefreshUI();
    }, 200);
  }
  
  SetDragEnd() {
    this.dragService.dragginEvent = false;
    this.RefreshUI();
  }
  
  Throttle(func, delay, context, args) {
    clearTimeout(this.inDebounce);
    this.inDebounce = setTimeout(() => func.apply(context, arguments), delay);
  }
  
  ShowEventUses(mouseEvent: any, event: LeapXLEvent) {
    this.timerEventSourceClick = setTimeout(() => {
      if (!this.preventEventSourceClick) {
        this.draggableWindowService.OpenDraggableWindow(
          `${ event.EventName } Connections`,
          DraggableWindowType.EventSource,
          mouseEvent,
          { event: event },
        );
      }
      this.preventEventSourceClick = false;
    }, 300);
  }
  
  RemoveUnusedEvents(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
    
    const eventsToRemove = [];
    
    this.eventsService.CustomEvents.forEach(customEvent => {
      const repMoleculesUsingEvent = this.busService.GetRepresentativeMoleculesWithEvent(
        customEvent.EventName);
      if (repMoleculesUsingEvent.length > 0) {
      } else {
        eventsToRemove.push(customEvent);
      }
    });
    
    if (eventsToRemove.length > 0) {
      this.eventsService.RemoveCustomEvents(eventsToRemove);
      this.snackerService.ShowMessageOnBottom(`${ eventsToRemove.length } unused events removed`,
        'do_not_disturb_on');
      this.RefreshUI();
    } else {
      this.snackerService.ShowMessageOnBottom('No events to remove');
    }
    
    this.GroupCustomEvents();
  }
  
  RemoveEvent(e: MouseEvent, customEvent: LeapXLEvent) {
    e.stopPropagation();
    e.preventDefault();
    
    const repMoleculesUsingEvent = this.busService.GetRepresentativeMoleculesWithEvent(customEvent.EventName);
    if (repMoleculesUsingEvent.length > 0) {
      this.genericDialogService
      .OpenConfirmDialog({
        title: `Custom event being used`,
        message: 'This event is in use by one or multiple representative molecules, its recommended to remove from' +
          ' the buses where it is' +
          ' attached.',
        confirmText: 'Ok',
        cancelText: 'See uses',
        optionalText: 'Delete Anyways',
        centerText: false,
      })
      .then(result => {
        
        if (result === 'optional') {
          this.DeleteEvent(customEvent);
        }
        
        if (result === false) {
          this.genericDialogService.closeDialog();
          this.ShowEventUses(e, customEvent);
        }
      });
    } else {
      this.DeleteEvent(customEvent);
    }
  }
  
  GroupCustomEvents(filteredEvents?: LeapXLEvent[]) {
    this.eventsService.CustomEvents.sort(this.toolsService.CompareValues('EventName'));
    
    const groupEvents = [];
    let groups;
    
    if (filteredEvents) {
      this.groupedCustomEvents = [];
      groups = Array.from(new Set(filteredEvents.map(event => event.EventName.charAt(0)
      .toUpperCase())
      .sort()));
    } else {
      groups = Array.from(new Set(this.eventsService.CustomEvents.map(event => event.EventName.charAt(0)
      .toUpperCase())
      .sort()));
    }
    
    groups.forEach(letter => {
      let events;
      
      if (filteredEvents) {
        events = filteredEvents.filter(ce => ce.EventName[0].toUpperCase() === letter);
      } else {
        events = this.eventsService.CustomEvents.filter(ce => ce.EventName[0].toUpperCase() === letter);
      }
      
      if (events.length > 0) {
        groupEvents.push({
          group: letter,
          events: events,
        });
      }
    });
    
    this.groupedCustomEvents = groupEvents;
    return groupEvents;
  }
  
  ToggleSection(section: any) {
    if (!this.eventsSearch.value) {
      for (let [key, value] of Object.entries(this.workAreaService.eventsPanelSectionsState)) {
        if (key !== section) {
          this.workAreaService.eventsPanelSectionsState[key] = false;
        }
      }
    }
    
    if (this.workAreaService.eventsPanelSectionsState[section]) {
      this.workAreaService.eventsPanelSectionsState[section] = !this.workAreaService.eventsPanelSectionsState[section];
    } else {
      this.workAreaService.eventsPanelSectionsState[section] = true;
      if (section === 'elements' && this.workAreaService.elementsSelected.length === 1) {
        setTimeout(() => {
          this.workAreaService.elementsSelected[0].HighlightEventsPath();
        }, 50);
      }
    }
    
    this.RefreshUI();
    this.setTreeViewportHeight();
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  
  setTreeViewportHeight() {
    this.windowHeight = window.innerHeight;
    if (this.eventsSearch.value) {
      const eventsPanel = document.querySelector('.events-panel');
      if (eventsPanel) {
        this.renderer.setStyle(eventsPanel, 'max-height', `${ this.windowHeight - 156 }px`);
      }
      return;
    }
    const element = document.querySelector('.events-panel .event-section-body');
    if (!element) {
      return;
    }
    const headersHeight = Constants.SidePanelSectionHeaderHeight * 5;
    const separatorsHeight = Constants.SeparatorSectionHeaderHeight * 4;
    const toolbarHeight = this.workAreaService.editorPreferences.compactToolBarPosition === 'top' ? Constants.ToolbarHeight : 0;
    this.renderer.setStyle(
      element,
      'max-height',
      `${
        this.windowHeight - ((this.workAreaService.eventsPanelSectionsState['custom'] ? 160 : 105) + headersHeight + separatorsHeight + toolbarHeight)
      }px`,
    );
    this.renderer.setStyle(element, 'overflow', 'auto');
  }
  
  setLastUsedElements() {
    if (!this.workAreaService.lastUsedElements.event) {
      return;
    }
    
    for (const [key, value] of Object.entries(this.lastUsedEvents)) {
      if (this.workAreaService.lastUsedElements.event[key]) {
        this.lastUsedEvents[key] = this.workAreaService.lastUsedElements.event[key];
      }
    }
  }
  
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowHeight = event.target.innerHeight;
    this.setTreeViewportHeight();
  }
  
  private DeleteEvent(event: LeapXLEvent) {
    this.eventsService.RemoveCustomEvents([event]);
    this.snackerService.ShowMessageOnBottom(`${ event.EventName } event removed`,
      'do_not_disturb_on');
    this.GroupCustomEvents(
      this.customEventsSearch.value ? this.filterCustomEvents(this.customEventsSearch.value) : null);
    this.RefreshUI();
  }
}
