import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CompactType, DisplayGrid, GridsterConfig, GridsterItem, GridType } from '@leapxl/gridster';
import { of, Subject } from 'rxjs';
import { debounceTime, delay, flatMap, map } from 'rxjs/operators';
import { BuilderService } from '../../../../core/builder/builder.service';
import { Receptor } from '../../../../core/molecular/receptors.enum';
import { ProcessorService } from '../../../../core/molecular/services/processor.service';
import { ApiDataSourcesService } from '../../../../core/services/api-data-sources.service';
import { WorkAreaService } from '../../../../workarea/workarea.service';
import { Constants } from '../../../constants';
import { PropertyVersioningDto } from '../../../dtos/versioning-dto';
import { DatasourceType } from '../../../enums/datasource-type.enum';
import { LeapXLEventType } from '../../../enums/leapxl-event-type.enum';
import { RepresentativeMoleculesType } from '../../../enums/representative-molecules-types.enum';
import { BadgeMoleculeComponent } from '../../badge-molecule/badge-molecule.component';
import { IRepresentativeMolecule } from '../../interfaces/representative-molecule.interface';
import { CobbleService } from '../../services/cobble.service';
import { DragService } from '../../services/drag.service';
import { BaseMoleculeComponent } from '../base-molecule/base-molecule.component';
import { BreadcrumbMoleculeComponent } from '../breadcrumb-molecule/breadcrumb-molecule.component';
import { ButtonMoleculeComponent } from '../button-molecule/button-molecule.component';
import { ChartMoleculeComponent } from '../chart-molecule/chart-molecule.component';
import { CheckboxMoleculeComponent } from '../checkbox-molecule/checkbox-molecule.component';
import { DatepickerMoleculeComponent } from '../datepicker-molecule/datepicker-molecule.component';
import { DropdownMoleculeComponent } from '../dropdown-molecule/dropdown-molecule.component';
import { H1MoleculeComponent } from '../h1-molecule/h1-molecule.component';
import { H2MoleculeComponent } from '../h2-molecule/h2-molecule.component';
import { H3MoleculeComponent } from '../h3-molecule/h3-molecule.component';
import { H4MoleculeComponent } from '../h4-molecule/h4-molecule.component';
import { H5MoleculeComponent } from '../h5-molecule/h5-molecule.component';
import { IconMoleculeComponent } from '../icon-molecule/icon-molecule.component';
import { IframeMoleculeComponent } from '../iframe-molecule/iframe-molecule.component';
import { ImageMoleculeComponent } from '../image-molecule/image-molecule.component';
import { LabelMoleculeComponent } from '../label-molecule/label-molecule.component';
import { TextareaMoleculeComponent } from '../textarea-molecule/textarea-molecule.component';
import { TextboxMoleculeComponent } from '../textbox-molecule/textbox-molecule.component';

declare var log;

@Component({
  selector: 'app-table-molecule',
  templateUrl: './table-molecule.component.html',
  styleUrls: ['./table-molecule.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  // changeDetection: document.location.href.includes('/run/')
  //   ? ChangeDetectionStrategy.OnPush
  //   : ChangeDetectionStrategy.Default
})
export class TableMoleculeComponent extends BaseMoleculeComponent implements OnInit, OnDestroy, AfterViewInit {
  MoleculeType = 'Representative';
  Type = RepresentativeMoleculesType.Table;
  searchField = '';
  public filterSearch = new Subject<any>();
  tableGridsterOptions: GridsterConfig;
  headChildren = {};
  columnsOrder = [];
  headerColumnsOrder = {};
  tableWidth = 0;
  orderedData = [];
  removeOldChildren = false;
  children: IRepresentativeMolecule[] = [];
  rowSelected = 0;
  tableChildren = {};
  dragStart;
  sizeStart;
  colsCount: {
    firstCol: number;
    lastCol: number;
  } = {
    firstCol: 9999999,
    lastCol: 0,
  };
  columnIndexes = [];
  rows = 0;
  cols = 0;
  beforeSearchPageNumber = 1;
  @ViewChildren('allTheseThings') things: QueryList<any>;
  sortOrder = 0;
  sortIndex = 0;
  sorted = false;
  maxChildrenCols = 15;
  maxChildrenRows = 15;
  columnsWidth = {};
  
  private moleculeComponents = {
    Button: ButtonMoleculeComponent,
    Badge: BadgeMoleculeComponent,
    Breadcrumb: BreadcrumbMoleculeComponent,
    Textbox: TextboxMoleculeComponent,
    Textarea: TextareaMoleculeComponent,
    Label: LabelMoleculeComponent,
    Iframe: IframeMoleculeComponent,
    Datepicker: DatepickerMoleculeComponent,
    Dropdown: DropdownMoleculeComponent,
    H1: H1MoleculeComponent,
    H2: H2MoleculeComponent,
    H3: H3MoleculeComponent,
    H4: H4MoleculeComponent,
    H5: H5MoleculeComponent,
    Image: ImageMoleculeComponent,
    Checkbox: CheckboxMoleculeComponent,
    Icon: IconMoleculeComponent,
    Chart: ChartMoleculeComponent,
  };
  
  constructor(
    public builderService: BuilderService,
    public dragService: DragService,
    public workAreaService: WorkAreaService,
    public processorService: ProcessorService,
    public cobbleService: CobbleService,
    private dataSourceService: ApiDataSourcesService,
    public bottomSheet: MatBottomSheet,
    private ref: ChangeDetectorRef,
    public el: ElementRef<HTMLElement>,
    changeDetectorRef: ChangeDetectorRef,
  ) {
    super(bottomSheet, el, changeDetectorRef);
  }
  
  get ChildrenSubParentIds() {
    return this.children.map(c => c.SubParentId);
  }
  
  get ChildrenSubParentContexts() {
    return this.children.map(c => c.SubParentContext);
  }
  
  ngAfterViewInit() {
    super.ngAfterViewInit();
  }
  
  ngOnInit() {
    // if (this.cobbleService.Cobble.running) {
    //   this.ref.detach();
    // }
    // this.LoadingTable(true);
    this.RefreshGridsterConfiguration();
    console.log(this.context.Id);
    super.ngOnInit();
    
    if (this.context.RunningMode) {
      this.Subscriptions.add(
        this.communicationService.Event.Editor.$SearchRepresentativeTables.subscribe(data => {
          console.log('=event=');
          if (this.context.Id.toString() === data.repMoleculeId.toString()) {
            this.context.Filter = data.value;
            if (data.value === null || data.value === '') {
              this.context.PageNumber = this.beforeSearchPageNumber;
            } else {
              this.beforeSearchPageNumber = this.context.PageNumber;
              this.context.PageNumber = 1;
            }
            
            this.PageChanged(this.context.PageNumber);
          }
        }),
      );
    }
    
    this.Subscriptions.add(
      this.communicationService.Event.System.Update.$ChangesOnMolecules.subscribe(
        (molecule: IRepresentativeMolecule) => {
          console.log('=event='); // console.log('$ChangesOnMolecules');
          // todo: event slowing down table children load
          // if (molecule && molecule.Type === RepresentativeMoleculesType.Image) {
          //   this.ref.markForCheck();
          //   // return;
          // }
          
          // console.log('change on molecules tables');
          const lockedView = this.cobbleService.Cobble.properties.views.find(v => v.locked) || { id: 0 };
          
          this.ref.detectChanges();
          this.ref.markForCheck();
          if (
            !this.workAreaService.actualEditorViews.map(v => v.id)
            .includes(this.context.Properties.view) ||
            this.context.Properties.view === lockedView.id
          ) {
            return;
          }
          
          if (molecule) {
            if (this.busService.IsMyChild(this.context.Id, molecule.Id) || molecule.Id === this.context.Id) {
              if (this.tableGridsterOptions && this.tableGridsterOptions.api) {
                this.tableGridsterOptions.api.optionsChanged();
              }
              this.refreshChildren();
            }
          } else {
            if (this.tableGridsterOptions && this.tableGridsterOptions.api) {
              this.tableGridsterOptions.api.optionsChanged();
            }
            this.refreshChildren();
          }
        }),
    );
    
    this.Subscriptions.add(
      this.communicationService.Event.Editor.$RefreshRepresentativeTablesUI.subscribe(repMoleculeId => {
        console.log('=event=');
        if (this.context.Id === repMoleculeId) {
          this.RefreshRepresentativeTablesUI();
        }
      }),
    );
    
    this.Subscriptions.add(
      this.communicationService.Event.Runtime.System.$RemoveOldChildren.subscribe(repMoleculeId => {
        console.log('=event=');
        if (this.context.Id === repMoleculeId) {
          this.removeOldChildren = true;
        }
      }),
    );
    
    this.Subscriptions.add(
      this.communicationService.Event.Runtime.System.$TableSearch.subscribe(data => {
        console.log('=event=');
        if (this.context.Id === data.repMoleculeId) {
          this.searchField = data.value;
          this.SearchTable(data.value);
        }
      }),
    );
    
    this.filterSearch
    .pipe(
      map(event => {
        this.ref.detectChanges();
        return event.target.value;
      }),
      debounceTime(300),
      flatMap(search => of(search)
      .pipe(delay(150))),
    )
    .subscribe(value => {
      // this.SearchTable(value);
      if (value === null || value === '') {
        this.context.PageNumber = this.beforeSearchPageNumber;
      } else if (this.context.SearchFilter === '') {
        this.beforeSearchPageNumber = this.context.PageNumber;
        this.context.PageNumber = 1;
      }
      
      console.log('search fired');
      
      this.context.Filter = value;
      this.context.SearchFilter = value;
      this.FireRepresentativeMoleculeEvent('search', this.context.SearchFilter);
      this.PageChanged(this.context.PageNumber);
    });
    
    // region GRIDSTER OPTIONS
    this.tableGridsterOptions = this.cobbleService.Cobble.running
      ? {
        collision: false,
        gridType: GridType.Fit,
        compactType: CompactType.None,
        margin: 0,
        outerMargin: true,
        outerMarginTop: null,
        outerMarginRight: null,
        outerMarginBottom: null,
        outerMarginLeft: null,
        useTransformPositioning: true,
        mobileBreakpoint: 0,
        minCols: this.maxChildrenCols,
        maxCols: this.maxChildrenCols,
        minRows: this.maxChildrenRows,
        maxRows: this.maxChildrenRows,
        maxItemCols: 15,
        minItemCols: 1,
        maxItemRows: 500,
        minItemRows: 1,
        maxItemArea: 100000,
        minItemArea: 1,
        defaultItemCols: 3,
        defaultItemRows: 3,
        fixedColWidth: 20,
        fixedRowHeight: 20,
        keepFixedHeightInMobile: false,
        keepFixedWidthInMobile: false,
        scrollSensitivity: 0,
        scrollSpeed: 0,
        enableEmptyCellClick: false,
        enableEmptyCellContextMenu: false,
        enableEmptyCellDrop: false,
        enableEmptyCellDrag: false,
        emptyCellDragMaxCols: 50,
        emptyCellDragMaxRows: 50,
        ignoreMarginInRow: false,
        draggable: {
          enabled: false,
        },
        resizable: {
          enabled: false,
        },
        swap: false,
        pushItems: true,
        disablePushOnDrag: false,
        disablePushOnResize: false,
        pushDirections: {
          north: true,
          east: true,
          south: true,
          west: true,
        },
        pushResizeItems: false,
        displayGrid: DisplayGrid.Always,
        disableWindowResize: false,
        disableWarnings: false,
        scrollToNewItems: false,
      }
      : {
        gridType: GridType.Fit,
        compactType: CompactType.None,
        margin: 0,
        outerMargin: true,
        outerMarginTop: null,
        outerMarginRight: null,
        outerMarginBottom: null,
        outerMarginLeft: null,
        useTransformPositioning: true,
        mobileBreakpoint: 0,
        minCols: this.maxChildrenCols,
        maxCols: this.maxChildrenCols,
        minRows: this.maxChildrenRows,
        maxRows: this.maxChildrenRows,
        maxItemCols: this.maxChildrenCols,
        minItemCols: 1,
        maxItemRows: 500,
        minItemRows: 1,
        maxItemArea: 100000,
        minItemArea: 1,
        defaultItemCols: 3,
        defaultItemRows: 3,
        fixedColWidth: 20,
        fixedRowHeight: 20,
        keepFixedHeightInMobile: false,
        keepFixedWidthInMobile: false,
        scrollSensitivity: 0,
        scrollSpeed: 0,
        enableEmptyCellClick: true,
        enableEmptyCellContextMenu: false,
        enableEmptyCellDrop: true,
        emptyCellDropCallback: (event: any, position: GridsterItem) => {
          position.x = 0;
          position.y = 0;
          console.log('event.srcElement ', (event.srcElement as any).parentElement.dataset);
          const subparentId = (event.srcElement as any).parentElement.dataset.subparentId;
          const subparentContext = (event.srcElement as any).parentElement.dataset.subparentContext;
          // console.log('subparentid', subparentId);
          
          this.templateService.GetActionMoleculeProperties(['GetCellDataElementMolecule'])
          .subscribe(moleculeProperties => {
            const buses = [
              {
                id: this.toolsService.GenerateGuid(),
                Name: `Process Table Button`,
                Receptor: Receptor.ValueOutput,
                Particles: [
                  this.particlesFactoryService.GenerateEvent(LeapXLEventType.Click),
                  this.particlesFactoryService.GenerateActionMolecule(moleculeProperties[0]),
                ],
              },
            ];
            
            this.OnDrop(
              this.dragService.dragData,
              event,
              position,
              [
                {
                  name: 'buses',
                  value: buses,
                  path: ``,
                },
                {
                  name: 'rows',
                  value: 14,
                  path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                },
                {
                  name: 'cols',
                  value: 14,
                  path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                },
                {
                  name: 'subParentContext',
                  value: subparentContext,
                  path: ``,
                },
              ],
              subparentId,
            );
          });
        },
        enableEmptyCellDrag: true,
        emptyCellDragMaxCols: 50,
        emptyCellDragMaxRows: 50,
        ignoreMarginInRow: false,
        draggable: {
          enabled: !this.cobbleService.Cobble.running,
          stop: (element: any) => {
            // console.log(element);
            
            const molecule = this.busService.Get(element.id);
            // console.log(molecule);
            
            setTimeout(() => {
              if (molecule.ResponsiveProperties().x !== this.dragStart.x || molecule.ResponsiveProperties().y !== this.dragStart.y) {
                this.propertiesService
                .SaveProperties([
                  new PropertyVersioningDto({
                    elementId: element.id,
                    property: 'x',
                    value: element.x,
                    path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                    change: `Position Changed`,
                    name: 'Position - X',
                  }),
                  new PropertyVersioningDto({
                    elementId: element.id,
                    property: 'y',
                    value: element.y,
                    path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                    change: `Position Changed`,
                    name: 'Position - Y',
                  }),
                ])
                .subscribe();
              }
            }, 300);
          },
          start: (element: any) => {
            this.workAreaService.elementClicked = this.busService.Get(element.id);
            
            // console.log('init drag');
            this.dragStart = {
              x: element.x,
              y: element.y,
            };
          },
        },
        resizable: {
          enabled: !this.cobbleService.Cobble.running,
          stop: (element: any) => {
            const molecule = this.busService.Get(element.id);
            
            setTimeout(() => {
              if (molecule.ResponsiveProperties().cols !== this.sizeStart.cols || molecule.ResponsiveProperties().rows !== this.sizeStart.rows) {
                if (this.busService.Get(element.id).GridsterConfig) {
                  this.busService.Get(element.id)
                  .GridsterConfig
                  .api
                  .optionsChanged();
                }
                
                this.propertiesService
                .SaveProperties([
                  new PropertyVersioningDto({
                    elementId: element.id,
                    property: 'cols',
                    value: element.cols,
                    path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                    change: `Size Changed`,
                    name: 'Size - Cols',
                  }),
                  new PropertyVersioningDto({
                    elementId: element.id,
                    property: 'rows',
                    value: element.rows,
                    path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
                    change: `Size Changed`,
                    name: 'Size - Rows',
                  }),
                ])
                .subscribe();
              }
            }, 200);
          },
          start: (element: any) => {
            // console.log('init drag');
            this.sizeStart = {
              cols: element.cols,
              rows: element.rows,
            };
          },
        },
        swap: false,
        pushItems: this.workAreaService.editorPreferences.push,
        disablePushOnDrag: false,
        disablePushOnResize: false,
        pushDirections: { north: true, east: true, south: true, west: true },
        pushResizeItems: false,
        displayGrid: DisplayGrid.None,
        disableWindowResize: false,
        disableWarnings: false,
        scrollToNewItems: false,
      };
    // endregion
    
    // this.UpdateData();
    // console.log('init table');
    this.children = this.GetTableDirectChildren();
    this.GetHeaderColumnsOrder();
    this.ref.markForCheck();
    
    console.log('table value', this.context.Value);
    
    if (this.context.Value && this.context.Value.length > 0) {
      if (this.context.ProcessedValue) {
        this.orderedData = this.context.ProcessedValue;
        this.CalculateColumnsWidth();
      } else {
        this.UpdateData();
      }
      
      console.log('orderedData', this.orderedData);
      this.ref.markForCheck();
    }
    
    this.CalculateColumnsWidth();
  }
  
  SearchTable(value: string) {
    if (value === null || value === '') {
      this.context.PageNumber = this.beforeSearchPageNumber;
    } else if (this.context.SearchFilter === '') {
      this.beforeSearchPageNumber = this.context.PageNumber;
      this.context.PageNumber = 1;
    }
    
    this.context.Filter = value;
    this.context.SearchFilter = value;
    this.FireRepresentativeMoleculeEvent('search', value);
    this.PageChanged(this.context.PageNumber);
  }
  
  RefreshRepresentativeTablesUI() {
    if (this.context.ProcessedValue) {
      this.RefreshUI();
      if (this.tableGridsterOptions && this.tableGridsterOptions.api) {
        this.tableGridsterOptions.api.optionsChanged();
      }
      
      if (this.context.ProcessedValue) {
        this.orderedData = [];
        setTimeout(() => {
          this.orderedData = this.context.ProcessedValue;
          this.CalculateColumnsWidth();
        }, 10);
      }
    }
  }
  
  GetHeaderColumnsOrder() {
    this.headerColumnsOrder = {};
    const buses = this.context.GetBusByReceptorAndMoleculeNameType([Receptor.HeaderInput],
      'GetElementsDatasourceDataMolecule');
    if (buses.length > 0) {
      const headerBus = buses[0];
      headerBus.GetLastParticleDataElements()
      .forEach((de, index) => {
        const repMolecule = this.children.find(
          c =>
            (c.SubParentContext && c.SubParentContext !== '' && this.BelongsToContext(c.SubParentContext,
              de.Context)) ||
            ((c.SubParentContext === null || c.SubParentContext === undefined || c.SubParentContext === '') && c.SubParentId === index + 1),
        );
        const repMoleculeId = repMolecule ? repMolecule.Id : 0;
        this.headerColumnsOrder[index] = repMoleculeId;
      });
      console.log('header', this.headerColumnsOrder);
    }
  }
  
  UpdateData() {
    this.rowSelected = 0;
    this.GetHeaderColumnsOrder();
    this.CalculateColumnsWidth();
    this.LoadingTable(true);
    console.log('updating table data', this.context);
    this.columnIndexes = [];
    
    this.tableWidth = 0;
    Object.keys(this.context.ResponsiveProperties().tableOptions.tableWidth)
    .forEach(key => {
      const colData = this.context.ResponsiveProperties().tableOptions.tableWidth[key];
      this.tableWidth += colData.columnWidth;
      
      if (colData.columnShow) {
        this.columnIndexes.push(+key);
      }
    });
    
    this.children = this.GetTableDirectChildren();
    this.tableWidth = 0;
    Object.keys(this.context.ResponsiveProperties().tableOptions.tableWidth)
    .forEach(key => {
      this.tableWidth += this.context.ResponsiveProperties().tableOptions.tableWidth[key].columnWidth;
    });
    this.ref.markForCheck();
    
    if (this.context.Value) {
      this.RefreshData(null);
    } else {
      this.LoadingTable(false);
    }
    
    this.communicationService.Event.Editor.$RefreshRepresentativePropertiesPanel.emit();
  }
  
  RefreshDataThrottle(data: any) {
    this.Throttle(
      (func, delay, context) => {
        if (this.context.Value) {
          this.RefreshData(data);
        }
      },
      100,
      this,
      data,
    );
  }
  
  RemoveOldChildRepMolecules() {
    this.RemoveDynamicChildrenAddedFromEventsTable();
    
    for (const [key, value] of Object.entries(this.tableChildren)) {
      this.busService.Remove(value as any);
    }
    
    this.ClearTableChildrenCache();
  }
  
  RefreshData(data: any) {
    // this.context.Value = [
    //   {
    //     affectedBy: [],
    //     affectingTo: [],
    //     col: 1,
    //     comment: null,
    //     context: 'test0',
    //     dataKey: null,
    //     formattedValue: 'erwt',
    //     formula: null,
    //     row: 1,
    //     orderCol: 1,
    //     value: 'ertre',
    //   },
    
    //   {
    //     affectedBy: [],
    //     affectingTo: [],
    //     col: 3,
    //     comment: null,
    //     context: 'test0',
    //     dataKey: null,
    //     formattedValue: 'ewrt',
    //     formula: null,
    //     row: 1,
    //     orderCol: 3,
    //     value: 'ertre',
    //   },
    //   {
    //     affectedBy: [],
    //     affectingTo: [],
    //     col: 1,
    //     comment: null,
    //     context: 'test0',
    //     dataKey: null,
    //     formattedValue: 'ewrt',
    //     formula: null,
    //     row: 2,
    //     orderCol: 1,
    //     value: 'ertre',
    //   },
    //   {
    //     affectedBy: [],
    //     affectingTo: [],
    //     col: 2,
    //     comment: null,
    //     context: 'test0',
    //     dataKey: null,
    //     formattedValue: 'ewrt',
    //     formula: null,
    //     row: 2,
    //     orderCol: 2,
    //     value: 'ertre',
    //   },
    //   {
    //     affectedBy: [],
    //     affectingTo: [],
    //     col: 3,
    //     comment: null,
    //     context: 'test0',
    //     dataKey: null,
    //     formattedValue: 'ewrt',
    //     formula: null,
    //     row: 2,
    //     orderCol: 3,
    //     value: 'ertre',
    //   },
    // ];
    console.log(this.context.Id, this.context.Value);
    setTimeout(() => {
      this.LoadingTable(false);
    }, 100);
    // console.log('value', this.context.Value);
    
    for (let i = 0; i < this.context.Value.length; i++) {
      const cell = this.context.Value[i] as { row: number; col: number };
      
      if (this.rows > cell.row) {
        break;
      }
      
      if (this.rows < cell.row) {
        this.rows++;
      }
      if (this.cols < cell.col) {
        this.cols++;
      }
    }
    
    // console.log(this.rows, this.cols);
    
    let rowSeparatedData = this.context.Value;
    console.log('value', this.context.Value);
    
    if (Array.isArray(this.context.Value)) {
      if (Array.isArray(this.context.Value[0])) {
      } else {
        rowSeparatedData = Array.from(this.toolsService.GroupBy(this.context.Value, v => v.row))
        .map(d => d[1]);
      }
    }
    
    console.log('rowSeparatedData', rowSeparatedData);
    
    rowSeparatedData.forEach(row => {
      const rowLastCol = Math.max.apply(
        Math,
        row.map(function(o) {
          return o.col;
        }),
      );
      
      const rowMinCol = Math.min.apply(
        Math,
        row.map(function(o) {
          return o.col;
        }),
      );
      
      this.colsCount.lastCol = rowLastCol > this.colsCount.lastCol ? rowLastCol : this.colsCount.lastCol;
      this.colsCount.firstCol = rowMinCol < this.colsCount.firstCol ? rowMinCol : this.colsCount.firstCol;
    });
    
    // console.log('last col', this.colsCount.lastCol);
    // console.log('min col', this.colsCount.firstCol);
    
    // complete any missing cell
    
    const completeRowIndex = rowSeparatedData.reduce(
      (maxI, el, i, arr) => (el.length > arr[maxI].length ? i : maxI), 0);
    const completeRow = rowSeparatedData[completeRowIndex];
    
    // const t = rowSeparatedData
    // .map((row) =>
    //   row.map((c) => {
    //     return { col: c.col, orderCol: c.orderCol };
    //   })
    // )
    // .flat();
    // const t = rowSeparatedData.map((row) => row.map((c) => c.col)).flat();
    // let cols = [...new Set(t)];
    // console.log('teto', t);
    
    console.log('rowSeparatedData', rowSeparatedData);
    
    if (completeRow) {
      const cols = completeRow.map(c => c.orderCol)
      .sort();
      
      rowSeparatedData.forEach(row => {
        if (row.length === cols.length) {
        } else {
          cols.forEach(col => {
            if (row.find(c => c.orderCol === col)) {
            } else {
              row.push({
                affectedBy: [],
                affectingTo: [],
                col: col,
                comment: null,
                context: '',
                dataKey: null,
                formattedValue: '',
                formula: null,
                row: row[0].row,
                orderCol: col,
                value: '',
              });
            }
          });
        }
      });
    }
    //
    
    this.orderedData = [];
    if (this.removeOldChildren) {
      this.RemoveOldChildRepMolecules();
      this.removeOldChildren = false;
    }
    
    // Check for more columns than data
    let extraColumns = 0;
    if (rowSeparatedData && rowSeparatedData.length > 0 && rowSeparatedData[0].length < Object.keys(
      this.headerColumnsOrder).length) {
      extraColumns = Object.keys(this.headerColumnsOrder).length - rowSeparatedData[0].length;
    }
    
    rowSeparatedData.forEach((row: any[], index) => {
      // if more columns, complete with empty values
      if (extraColumns > 0 && row.length < Object.keys(this.headerColumnsOrder).length) {
        const rowLastCol = Math.max.apply(
          Math,
          row.map(function(o) {
            return o.col;
          }),
        );
        
        for (let i = rowLastCol + 1; i <= rowLastCol + extraColumns; i++) {
          row.push({
            affectedBy: [],
            affectingTo: [],
            col: i,
            comment: null,
            context: '',
            dataKey: null,
            formattedValue: '',
            formula: null,
            row: row[0].row,
            orderCol: i,
            value: '',
          });
        }
      }
      
      if (row[0].orderCol) {
        row.sort((a, b) => 0 - (a.orderCol > b.orderCol ? -1 : 1));
      }
      
      // hiding cols with width 0
      row.forEach((cell, index) => {
        cell.showCol = this.GetColumnWidthForData(index) > 0;
      });
      
    });
    
    const firstRow = rowSeparatedData[0];
    
    // clearing children cache if dataset changes
    if (firstRow && this.tableChildren) {
      const firstCachedChildren = Object.values(this.tableChildren)[0];
      
      if (firstCachedChildren && (firstCachedChildren as any).dataKey && (firstCachedChildren as any).dataKey !== firstRow.dataKey) {
        this.ClearTableChildrenCache();
      }
    }
    //
    
    console.log(this.headerColumnsOrder);
    this.orderedData = this.toolsService.CloneObject(rowSeparatedData);
    this.CalculateColumnsWidth();
    this.context.ProcessedValue = this.orderedData;
    console.log('orderedData', this.orderedData);
    console.log('header value', this.context.HeaderValue);
    
    // this.communicationService.Event.System.Update.$ChangesOnMolecules.emit(null);
    // this.ref.markForCheck();
    
    // // console.log(
    //   'table order',
    //   this.context.ResponsiveProperties()
    //     .tableOptions.tableOrder.length > 0
    // );
    
    if (this.context.ResponsiveProperties().tableOptions.tableOrder.length > 0) {
      this.updateColumnsOrder();
    } else {
      this.columnsOrder = this.orderedData[0];
      this.RefreshUI();
    }
  }
  
  ClearTableChildrenCache() {
    this.tableChildren = {};
  }
  
  LoadingTable(loading: boolean) {
    if (loading) {
      this.context.Loading = true;
      this.ref.detectChanges();
      this.ref.markForCheck();
    } else {
      this.context.Loading = false;
      setTimeout(() => {
        this.ref.detectChanges();
        this.ref.markForCheck();
      }, 200);
    }
  }
  
  PageChanged(pageNumber: number) {
    console.log('page changed', pageNumber);
    
    this.context.PageChanged = true;
    this.context.PageNumber = pageNumber;
    this.context.GetPaginatedData(this.context.SearchFilter)
    .subscribe(data => {
      if (data) {
        this.context.SetValueMetaData(data.metaData, data.dataKey);
        const valueBus = this.context.GetBusByReceptor('value-input');
        const datasourceMolecule =
          valueBus.GetActionMoleculeParticle('GetElementsDatasourceDataMolecule') ||
          valueBus.GetActionMoleculeParticle('FilterByDataElementReferenceMolecule');
        if (datasourceMolecule) {
          this.molecularEngineConnectorService
          .RunMolecule('FilterByDataElementReferenceMolecule', data.dataElements,
            datasourceMolecule.DataElements, this.context.Id)
          .subscribe(processData => {
            if (processData.repMoleculeId === this.context.Id) {
              this.context.SetValue(processData.data);
              setTimeout(() => {
                this.FireRepresentativeMoleculeEvent('page-changed', pageNumber);
              }, 250);
            }
          });
        } else {
          this.context.SetValue(data.dataElements);
          setTimeout(() => {
            this.FireRepresentativeMoleculeEvent('page-changed', pageNumber);
          }, 250);
        }
      }
    });
  }
  
  RemoveDynamicChildrenAddedFromEventsTable() {
    const childrenIds = [];
    
    Object.keys(this.tableChildren)
    .forEach(key => {
      childrenIds.push(this.tableChildren[key]);
    });
    
    this.eventsService.RemoveFromEventsTable(childrenIds);
  }
  
  emptyCellDrop(event: DragEvent, position: GridsterItem) {
    // console.log('empty cell drop');
    
    const subparentId = (event.srcElement as any).parentElement.dataset.subparentId;
    this.OnDrop(
      this.dragService.dragData,
      event,
      position,
      [
        {
          name: 'rows',
          value: 10,
          path: 'properties',
        },
      ],
      subparentId,
    );
  }
  
  swap(
    row: {
      row: number;
      col: number;
      value: string;
      formula: any;
      inRange: boolean;
    }[],
    from: number,
    to: number,
  ): any[] {
    // console.log('swap');
    
    return row.reduce((prev, current, idx, self) => {
      if (from === to) {
        prev.push(current);
      }
      if (idx === from) {
        return prev;
      }
      if (from < to) {
        prev.push(current);
      }
      if (idx === to) {
        prev.push(self[from]);
      }
      if (from > to) {
        prev.push(current);
      }
      return prev;
    }, []);
  }
  
  array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  }
  
  GetNextNumber(numbers: number[]) {
    numbers = numbers.sort();
    
    for (let index = 0; index < numbers.length; index++) {
      const number = numbers[index];
      
      if (number + 1 !== numbers[index + 1]) {
        return number + 1;
      }
    }
  }
  
  public TrackCell(index, item) {
    if (!item) {
      return null;
    }
    
    // // console.log(item);
    
    return item.col.toString() + item.row.toString();
  }
  
  public GetElementComponent(
    cell: {
      col: number;
      orderCol: number;
      row: number;
      value: any;
      context: string;
      dataKey: string;
      formattedValue: any;
    },
    colIndex = null,
  ): BaseMoleculeComponent {
    const child = this.GetChildren(cell, colIndex);
    return this.moleculeComponents[child.Type];
  }
  
  public GetHeaderElementComponent(
    cell: {
      col: number;
      row: number;
      value: any;
    },
    colIndex: number,
  ): BaseMoleculeComponent {
    const child = this.children.find(c => c.Id === this.headerColumnsOrder[colIndex]);
    return this.moleculeComponents[child.Type];
  }
  
  GetChildren(
    cell: {
      col: number;
      orderCol: number;
      row: number;
      value: any;
      context: string;
      dataKey: string;
      formattedValue: string;
    },
    colIndex = null,
  ) {
    if (colIndex) {
      cell.col = cell.col || colIndex;
    }
    
    const renderedChildrenId = this.tableChildren[`${ cell.col }-${ cell.row }-${ cell.dataKey }`];
    if (renderedChildrenId > 0) {
      const childrenRepMolecule = this.busService.Get(renderedChildrenId);
      childrenRepMolecule.CellDataElement = cell;
      
      return childrenRepMolecule;
    }
    
    const minId = 11111111;
    const repMoleculeId = this.headerColumnsOrder[colIndex];
    const child = this.children.find(c => c.Id === repMoleculeId);
    
    if (child.Id >= minId) {
      return child;
    }
    
    const childrenId = Math.floor(Math.random() * (999999999 - minId)) + minId;
    
    this.tableChildren[`${ cell.col }-${ cell.row }-${ cell.dataKey }`] = childrenId;
    const repMol = this.builderService.CreateAndAddRepresentativeMoleculeToBus(
      this.busService.GetRepresentativeMoleculeFromRawApp(child.Id),
      childrenId,
      false,
    );
    
    repMol.OriginalRepMoleculeId = child.Id;
    repMol.CellDataElement = cell;
    repMol.RowDataElements = this.context.Value.filter(v => v.row === cell.row);
    
    console.log(repMol);
    // todo: evaluate events table creation for children
    // this.eventsService.CreateEventsTable();
    this.eventsService.AddToEventsTable(Object.values(this.tableChildren));
    console.log('table events', this.eventsService.eventsTable);
    
    // this.CreaeEventsTableThrottle();
    return repMol;
  }
  
  BelongsToContext(originalContext: string, context: string): boolean {
    const splittedContext = context.split(Constants.ContextSeparator);
    const splittedOriginalContext = originalContext.split(Constants.ContextSeparator);
    
    switch (splittedContext[0]) {
      case DatasourceType.Spreadsheet:
        const originalContextReference = splittedOriginalContext.pop();
        const contextReference = splittedContext.pop();
        
        if (splittedContext.join(Constants.ContextSeparator) === splittedOriginalContext.join(
          Constants.ContextSeparator)) {
          return this.toolsService.RemoveDigits(originalContextReference) === this.toolsService.RemoveDigits(
            contextReference);
        } else {
          return false;
        }
        break;
      
      default:
        return originalContext === context;
    }
  }
  
  GetHeadChildren(cell: { col: number; row: number; value: any }, colIndex = null) {
    const repMoleculeId = this.headerColumnsOrder[colIndex];
    const child = this.children.find(c => c.Id === repMoleculeId);
    
    if (child) {
      child.Properties.id = child.Id;
    }
    return child;
  }
  
  HasChild(
    col: {
      col: number;
      orderCol: number;
      context: string;
    },
    colIndex = null,
  ): boolean {
    // console.log(this.ChildrenSubParentIds, col);
    return this.headerColumnsOrder[colIndex] > 0;
  }
  
  GetColumnWidth(col: number, index?: number) {
    let colWidth = this.context.ResponsiveProperties().tableOptions.tableWidth[`${ col }`];
    
    if (colWidth) {
    } else if (index) {
      const keys = Object.keys(this.context.ResponsiveProperties().tableOptions.tableWidth);
      colWidth = this.context.ResponsiveProperties().tableOptions.tableWidth[`${ keys[index] }`];
    }
    
    if (colWidth) {
      return colWidth.columnWidth;
    } else {
      return 120;
    }
  }
  
  CalculateColumnsWidth() {
    if (this.orderedData && this.orderedData.length > 0) {
      this.orderedData[0].forEach((col, index) => {
        this.columnsWidth[index + 1] = this.GetColumnWidth(index + 1);
      });
    } else {
      
      if (this.context.HeaderValue && this.context.HeaderValue.length > 0) {
        this.context.HeaderValue.forEach((col, index) => {
          this.columnsWidth[index + 1] = this.GetColumnWidth(index + 1);
        });
      }
    }
  }
  
  GetColumnWidthForData(index: number) {
    // console.log(index, this.context.HeaderValue);
    const headerCell = this.context.HeaderValue[index];
    return headerCell ? this.GetColumnWidth(headerCell.col) : 120;
  }
  
  dragTableHeader(e: any, drag: boolean) {
    setTimeout(() => {
      this.RemoveTableDragHighlight();
      if (drag) {
        if (e.target.classList.contains('table-header-gridster')) {
          e.target.classList.add('dragover');
        } else if (e.target.classList.contains('drop-table-head')) {
          e.target.parentNode.classList.add('dragover');
        }
      }
    }, 50);
  }
  
  dragTable(e: any, drag: boolean) {
    this.RemoveTableDragHighlight();
  }
  
  RemoveTableDragHighlight() {
    document.querySelectorAll(`#gridsterItem-${ this.context.Id } thead .dragover`)
    .forEach(el => {
      el.classList.remove('dragover');
    });
  }
  
  SetHeadChildren() {
    this.headChildren = {};
    // for (let index = 1; index <= this.context.DataSource.cols; index++) {
    //   this.headChildren[index] = this.GetHeadChildren({
    //     col: index,
    //     row: 0,
    //     value: '',
    //   });
    // }
    this.ref.markForCheck();
  }
  
  ClickOnElement(element: IRepresentativeMolecule, e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    
    if (!this.cobbleService.Cobble.running) {
      // console.log('rep mol', element);
      this.workAreaService.ShowElementFocusedMenu(element, e);
    }
  }
  
  MouseOver(e: any) {
    // console.log(e);
    e.preventDefault();
    e.stopPropagation();
    return false;
  }
  
  OnContextMenu(event: MouseEvent) {
    // console.log('event', event);
    event.stopPropagation();
    
    const context = (event.target as any).dataset.context;
    const contexts = this.context.Value.map(c => c.context);
    this.context.OnContextMenu(event, {
      contexts: [context],
      completeContexts: contexts,
    });
    return false;
  }
  
  SelectRow(row: any[]) {
    if (this.context.ResponsiveProperties().tableOptions.selectRow) {
      this.rowSelected = row && row.length > 0 ? row[0].row : 0;
      this.FireRepresentativeMoleculeEvent(LeapXLEventType.RowSelected, row, true);
    }
  }
  
  SortColumn(index: number) {
    if (this.context.ValueMetaData && this.context.ValueMetaData.PageCount > 1) {
      return;
    }
    
    this.sortIndex = index;
    this.sorted = true;
    
    this.orderedData = this.orderedData.sort((a, b) => {
      return this.sortOrder === 1 ?
        a[index].formattedValue.localeCompare(b[index].formattedValue, 'en',
          { sensitivity: 'base', numeric: true }) :
        b[index].formattedValue.localeCompare(a[index].formattedValue, 'en',
          { sensitivity: 'base', numeric: true });
    });
    
    this.sortOrder = this.sortOrder === 0 ? 1 : 0;
    this.RefreshUI();
  }
  
  private updateColumnsOrder() {
    // console.log('updating columns order');
    
    this.orderedData = [];
    this.ref.markForCheck();
    
    setTimeout(() => {
      // this.dataSource.data.forEach((row: any[]) => {
      //   this.context.Properties.responsive[
      //     this.cobbleService.Cobble.deviceType
      //   ].tableOptions.tableOrder.forEach(
      //     (order: { col: number; position: number }) => {
      //       const index = row.findIndex(
      //         (c) => c !== undefined && c.col === order.col
      //       );
      //       row = this.array_move(row, index, order.position);
      //     }
      //   );
      
      //   this.orderedData.push(row);
      // });
      
      this.columnsOrder = this.orderedData[0];
      this.ref.markForCheck();
    }, 50);
  }
  
  private refreshChildren() {
    this.children = this.GetTableDirectChildren();
    console.log(this.children);
    this.GetHeaderColumnsOrder();
    console.log('========TABLE CHILDREN LOADED======', this.children);
    setTimeout(() => {
      this.communicationService.Event.System.Update.$RefreshWorkgroups.emit(this.context.ParentId);
      setTimeout(() => {
        if (this.cobbleService.Cobble.running) {
          this.toolsService.TextFit();
          setTimeout(() => {
            this.toolsService.TextFit();
          }, 200);
        }
      }, 200);
      this.ref.detectChanges();
      this.ref.markForCheck();
    }, 100);
  }
  
  private GetTableDirectChildren() {
    const children = this.busService.DirectChildrenElements(this.context.Id);
    children.forEach(child => {
      child.ResponsiveProperties().cols = child.ResponsiveProperties().x + child.ResponsiveProperties().cols > this.maxChildrenCols ?
        this.maxChildrenCols - child.ResponsiveProperties().x : child.ResponsiveProperties().cols;
      
      child.ResponsiveProperties().rows = child.ResponsiveProperties().y + child.ResponsiveProperties().rows > this.maxChildrenRows ?
        this.maxChildrenRows - child.ResponsiveProperties().y : child.ResponsiveProperties().rows;
    });
    return children;
  }
  
}
